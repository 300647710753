import { useFormikContext } from "formik";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

const PaymentMethodRadioGroup = () => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Payment Method:</FormLabel>
      <RadioGroup
        aria-label="Payment Method"
        defaultValue="monthly"
        name="plan-radio-buttons-group"
        value={values.paymentMethod || ""}
        onChange={(e) => {
          setFieldValue("paymentMethod", e.target.value);
        }}
      >
        <FormControlLabel
          value="emailInvoice"
          control={<Radio />}
          label="Email an invoice for my accounting department to pay"
        />
        <FormControlLabel
          value="payNow"
          control={<Radio />}
          label="Pay now with credit card"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default PaymentMethodRadioGroup;
