import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { createContext, useContext } from "react";
import { stripeKey } from "../config";

const StripeContext = createContext();

const stripePromise = loadStripe(stripeKey);

const options = {
    // passing the client secret obtained from the server
    // clientSecret: "{{CLIENT_SECRET}}",
  };

function StripeProvider({ children }) {
  const value = {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
  };

  return (
    <StripeContext.Provider value={value}>
      <Elements stripe={stripePromise} options={options}>
        {children}
      </Elements>
    </StripeContext.Provider>
  );
}

function useStripeContext() {
  const context = useContext(StripeContext);
  if (context === undefined) {
    throw new Error("useStripeContext must be used within a StripeProvider");
  }
  return context;
}

export { StripeProvider, useStripeContext };
