import { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import axios from "axios";

import { db } from "../firebase";
import { usePaymentContext } from "../context/paymentContext";

const flattenCompanyData = (companyData) => {
  return Object.assign(
    {},
    ...(function _flatten(o) {
      return [].concat(
        ...Object.keys(o).map((k) =>
          typeof o[k] === "object" ? _flatten(o[k]) : { [k]: o[k] }
        )
      );
    })(companyData)
  );
};

const useDetermincationOfSetPayment = (company) => {
  const [, setPayment] = usePaymentContext();

  const paymentStructure = company
    ? {
        useSetPayment: !!company?.subscription?.amount,
        amount: company?.subscription?.amount,
      }
    : {
        useSetPayment: false,
      };

  useEffect(() => {
    setPayment(paymentStructure);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStructure?.useSetPayment]);
};

const useProposal = (proposalId) => {
  const [company, setCompany] = useState("loading");

  const defaultValues = {
    startDate: new Date(),
    timePeriod: "Annual",
    plan: "",
    emailInvoice: false,
  };

  useEffect(() => {
    if (proposalId) {
      const getCompany = async () => {
        const docSnapshot = await getDoc(
          doc(db, "companies", proposalId)
        );
        setCompany(docSnapshot?.data());
      };
      getCompany();
    } else {
      // set company to null to triger `else` no company data
      setCompany(null);
    }
  }, [proposalId]);

  useDetermincationOfSetPayment(company);

  // return early if loading
  if (company === "loading") {
    return { loading: true, loadingError: false };
  }

  // return with preloaded data if available
  if (company) {

    // do not allow the sign up form to be used with any company that already has an active subscription
    if (company.status == 'Active') {
      return { loading: false, loadingError: true };
    } else {

      // flattenCompanyData drops any datetime values, so need to flatten first, then add startDate back to it
      var company1 = flattenCompanyData(company);

      company1.proposalId = proposalId;

      if (company.subscription) {
        if ((company.subscription.startDate) && (company.subscription.startDate != '')) {
          company1.startDate = company.subscription.startDate.toDate();
        }
      }

      const saveView = async () => {

        // check the viewer's IP address to exclude members of the Grateful team
        try {
          const getIp = await axios.get('https://us-central1-given-e6ba8.cloudfunctions.net/getIpAddress');
          // var excluded = ['173.17.226.160', '173.20.162.229', '98.127.128.34'];
          var excluded = [];

          if (!excluded.includes(getIp.data)) {
            setDoc(doc(db, "company-signup-views", proposalId), {
              datetime: new Date(),
              contact: company.primaryContactName || '',
              company: company.name,
              ip: getIp.data,
              action: 'signup form viewed'
            }, { merge: true });
          }

        // if there are any issues, default to saving the view event
        } catch (error) {
          setDoc(doc(db, "company-signup-views", proposalId), {
            datetime: new Date(),
            contact: company.primaryContactName || '',
            company: company.name,
            ip: null,
            action: 'signup form viewed'
          }, { merge: true });
        }
      }
      saveView();

      return {
        loading: false,
        loadingError: false,
        company: { ...defaultValues, ...company1 },
      }; 
    }
  } else {
    // return default value if company unavailable; updated to return error instead
    return {
      loading: false,
      loadingError: true,
      // company: defaultValues,
    };
  }
};

export default useProposal;
