import { useFormikContext } from "formik";

import TextField from "@mui/material/TextField";

export default function EmailInput({ hideError, label, name, ...props }) {
  const { values, errors, handleChange } = useFormikContext();

  return (
    <TextField
      // dasherizes label
      /* id={`${label.replace(/\s+/g, "-")}-input`} */
      id={name}
      fullWidth
      error={!hideError && !!errors[name]}
      helperText={!hideError && errors[name] && `Please enter a valid email address`}
      // camel case of input
      name={name}
      value={values[name]}
      onChange={handleChange}
      label={label}
      variant="outlined"
      {...props}
    />
  );
}
