import StripeInput from "./StripeInput";
import TextField from "@mui/material/TextField";
import { useStripeContext } from "../../context/stripeContext";

export default function PaymentInputs() {
  const {CardNumberElement, CardExpiryElement, CardCvcElement} = useStripeContext()

  return (
    <>
      <TextField
        label="Credit Card Number"
        name="ccnumber"
        variant="outlined"
        required
        fullWidth
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: StripeInput,
          inputProps: {
            component: CardNumberElement,
          },
        }}
      />
      <TextField
        label="Expiration Date"
        name="ccexp"
        variant="outlined"
        required
        fullWidth
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: StripeInput,
          inputProps: {
            component: CardExpiryElement,
          },
        }}
      />
      <TextField
        label="CVC"
        name="cvc"
        variant="outlined"
        required
        fullWidth
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: StripeInput,
          inputProps: {
            component: CardCvcElement,
          },
        }}
      />
    </>
  );
}
