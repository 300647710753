import React from "react";

const StripeInput = React.forwardRef((props, ref) => {
  const { component: Component, ...other } = props;

  // implement `InputElement` interface
  React.useImperativeHandle(ref, () => ({
    focus: () => {
      // logic to focus the rendered component from 3rd party belongs here
    },
    // hiding the value e.g. react-stripe-elements
  }));

  // `Component` will be your `SomeThirdPartyComponent` from below
  return (
    <Component
      {...other}
      options={{
        style: {
          base: {
            fontSize: "16px",
            fontFamily:
              "roboto, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, sans-serif",
          },
        },
      }}
    />
  );
});

export default StripeInput;
