import { useFormikContext } from "formik";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";

export default function SpecialTermsImmutableInput({
  hideError,
  label,
  name,
  ...props
}) {
  const { values } = useFormikContext();

  const fontColor = {
    style: {
      color: "rgba(0, 0, 0, 1)",
      WebkitTextFillColor: "rgba(0, 0, 0, 1)",
    },
  };

  return (
    <>
      <FormLabel component="legend">Special Terms:</FormLabel>
      <TextField
        // dasherizes label
        id="special-terms"
        sx={{
          "& .MuiInputBase-root.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.6)", // (default alpha is 0.38)
          },
        }}
        fullWidth
        multiline
        value={values.terms}
        InputLabelProps={{ shrink: true }}
        disabled
        variant="outlined"
        inputProps={fontColor}
        {...props}
      />
    </>
  );
}