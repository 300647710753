import React from "react";

const LoadingSpinner = ({ displayText }) => (
  <div className="container">
    <div className="card overflow-visible bg-white">
      <div className="card-body">
        <div style={{ textAlign: "center" }}>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <br />
          <br />
          {displayText}
        </div>
      </div>
    </div>
  </div>
);

export default LoadingSpinner;
