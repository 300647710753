export const getSubscriptionPrice = (plan, timePeriod) => {
  switch (plan) {
    case "Small":
      if (timePeriod == "Monthly") {
        return 150;
      } else {
        return 1500;
      }
    case "Medium":
      if (timePeriod == "Monthly") {
        return 500;
      } else {
        return 5000;
      }
    case "Enterprise":
      if (timePeriod == "Monthly") {
        return 3000;
      } else {
        return 30000;
      }
    case "Usage":
      return 0;
    case "":
      return 0;
    default:
      throw new Error(
        `The provided subscription plan ${plan} is not defined.`
      );
  }
};

export const formatPrice = (price) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(price);
};
