import { useFormikContext } from "formik";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";

const DateInput = () => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormLabel component="legend">Subscription start date:</FormLabel>
      <DesktopDatePicker
        inputFormat="MM/dd/yyyy"
        value={values.startDate || ""}
        onChange={(e) => setFieldValue("startDate", e)}
        renderInput={(params) => <TextField {...params} fullWidth />}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
