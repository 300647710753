import { useFormikContext } from "formik";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

/* This element is not currently used, keeping it as an example if needed in the future */

const PlanRadioGroup = () => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Plan:</FormLabel>
      <RadioGroup
        aria-label="Plan"
        name="plan-radio-buttons-group"
        value={values.plan}
        onChange={(e) => {
          setFieldValue("plan", e.target.value);
        }}
      >
        {/*<FormControlLabel
          value="Usage"
          control={<Radio />}
          label="Usage Fee: fee charged on each charitable gift"
        />*/}
        <FormControlLabel
          value="Small"
          control={<Radio />}
          label="Small Business: up to $10,000/year in giving"
        />
        <FormControlLabel
          value="Medium"
          control={<Radio />}
          label="Medium Business: up to $50,000/year in giving"
        />
        <FormControlLabel
          value="Enterprise"
          control={<Radio />}
          label="Enterprise Business: up to $300,000/year in giving"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default PlanRadioGroup;
