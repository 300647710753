import { GRATEFUL_EMAIL } from "../constants";

const BusinessSignupDescribed = () => {
  return (
    <div className="mt-2 container narrow text-center">
      <div className="h1 mt-2 mb-1 text-primary" style={{fontSize: "3rem"}}>GRATEFUL BUSINESS SIGNUP</div>
      <div className="header-description">
        <div className="p-big">
          <p>
            Please complete the information below to start your Grateful subscription.<br />
            Use of Grateful is governed by our <a rel="noreferrer" target="_blank" href="useragreement">User Agreement</a> and <a rel="noreferrer" target="_blank" href="https://www.begrateful.org/privacy">Privacy Policy</a>.
          </p>
          <p>
            <a href={`mailto:${GRATEFUL_EMAIL}`}>Contact us</a> if you have any questions.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BusinessSignupDescribed;
