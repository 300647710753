import { collection, addDoc, setDoc, updateDoc, doc, getDocs, query, where } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import { db } from "../firebase";

const useCreateCompany = () => {

  const getStripeDataObject = (paymentMethod) => {
    return {
      stripeId: paymentMethod.id,
      type: paymentMethod.type,
      brand: paymentMethod.card.brand,
      last4: paymentMethod.card.last4,
      expMonth: paymentMethod.card.exp_month,
      expYear: paymentMethod.card.exp_year,
    };
  };

  /*
  Originally this code created a new company record, but I later changed it to update an existing prospect company record

  addCompany / companyToAdd names are misleading, it is updating only
  */
  const addCompany = async (companyData, paymentMethod) => {
    const companyToAdd = {};

    // set payment method
    if (paymentMethod != null) {
      const stripeData = getStripeDataObject(paymentMethod);
      companyToAdd.paymentMethod = stripeData;
    }

    // set subscription data
    const subscription = {
      amount: companyData.amount || 0,
      startDate: companyData.startDate,
      plan: companyData.plan,
      timePeriod: companyData.timePeriod,
      paymentMethod: companyData.paymentMethod || '',
    };
    companyToAdd.subscription = subscription;
    companyToAdd.status = 'Active';

    /*
    const settings = {
      coversAdminFee: true,
      matchGifts: false,
      matchLimit: 0,
      matchRate: 0,
    };
    companyToAdd.settings = settings;
    */

    // set other required fields
    companyToAdd.websiteUrl = companyData.websiteUrl.trim();
    companyToAdd.name = companyData.name.trim();
    companyToAdd.displayName = companyData.name.trim();
    // companyToAdd.proposalId = companyData.proposalId;

    // set optional field: mailing address
    const mailingAddress = {
      ...(companyData.streetAddress && {
        streetAddress: companyData.streetAddress,
      }),
      ...(companyData.city && { city: companyData.city }),
      ...(companyData.zipCode && { zipCode: companyData.zipCode }),
      ...(companyData.state && { state: companyData.state }),
    };
    if (Object.keys(mailingAddress).length > 0) {
      companyToAdd.mailingAddress = mailingAddress;
    }

    /*
    // set optional field: mailing address
    const branding = {
      cobrand: true,
      companyName: companyData.name,
      logoHeight: 50,
      poweredByText: "FFFFFF",
      welcomeMessage:
        "Browse specific needs to give to, or use the Search screen to search for additional nonprofits",
      ...(companyData.emailLogo && {
        emailLogo: companyData.emailLogo,
      }),
      ...(companyData.companyLogo && {
        companyLogo: companyData.companyLogo,
      }),
      ...(companyData.primaryColor && {
        appBarColor: companyData.primaryColor,
      }),
      ...(companyData.buttonColor && {
        buttonColor: companyData.buttonColor,
      }),
    };

    var domainElements = companyData.websiteUrl.split('.');
    if (domainElements.length > 2) {
      branding.subdomain = domainElements[domainElements.length-2];
    } else {
      branding.subdomain = domainElements[0];
    }

    if (Object.keys(branding).length > 0) {
      companyToAdd.branding = branding;
    }
    */

    if (companyData.primaryContactName) {
      companyToAdd.primaryContactName = companyData.primaryContactName;
    }

    if (companyData.primaryContactEmail) {
      companyToAdd.primaryContactEmail = companyData.primaryContactEmail.toLowerCase();
    }

    // if adding new user, create user account in Firebase Auth and add users record in Firestore
    var userId;
    if (companyData.password) {
      const auth = getAuth();
      await createUserWithEmailAndPassword(auth, companyData.primaryContactEmail.toLowerCase(), companyData.password)
        .then(async (userCredential) => {
          // Signed in 
          const user = userCredential.user;
          userId = user.uid;

          // create users document in Firestore
          await setDoc(doc(db, "users", userId), {
            name: companyData.primaryContactName,
            email: companyData.primaryContactEmail.toLowerCase(),
            userId: userId,
            stripeId: "",
            userBalance: 0,
            shareInfoWithNonprofits: "Always"
          });

          const userDocRef = doc(db, "users", userId);

          // add user details document with created date
          const userDetailsRef = collection(userDocRef, "details");
          const userDetailDocRef = doc(userDetailsRef, "detail");

          await setDoc(userDetailDocRef, {
            created: new Date(),
          });

          // a separate cloud function makes the user an admin for the company when the company record is saved
          // this applies both to existing users and to new users created here

        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode, errorMessage);
        });

    }

    // create company document
    // note: user should be created first, if needed
    // const docSnapshot = await addDoc(collection(db, "companies"), companyToAdd);
    
    const docSnapshot = doc(db, "companies", companyData.companyId);
    await updateDoc(docSnapshot, companyToAdd);

    /* 
    const companyId = docSnapshot.id;
    const updatedDoc = doc(db, "companies", companyId);
    await updateDoc(updatedDoc, { companyId: companyId });
    */
    // modified this to have the cloud functon add the companyId instead

    // setCompany(docSnapshot);
    return docSnapshot;
  };

  return addCompany;
};

export default useCreateCompany;
