import { useFormikContext } from "formik";


export default function StepThree() {
  const { values } = useFormikContext();

  return (
    <>
		<h2 style={{textAlign: "center"}}>Thank you, and welcome!</h2>
		<p style={{textAlign: "center"}}>
	        <img
	          src={values.emailLogo}
	          alt={values.name}
	          style={{ maxWidth: 300, maxHeight: 300}}
	        />
        </p>
		<p style={{textAlign: "center"}}><br />You are now signed up for Grateful!</p>
		<p style={{textAlign: "center"}}><br />You can login as {values.primaryContactEmail} to start using your new subscription.</p>
		<p style={{textAlign: "center"}}><br />Our support team will also reach out to you to schedule a short onboarding call,<br />or you can pick a date/time for the call by using our <a href="https://calendly.com/lisa-acheson-grateful/kickoff" target="_blank">Calendly link</a>.</p>
		<p style={{textAlign: "center"}}><br /><a href="https://app.begrateful.org">Login to Grateful here</a></p>
    </>
  );
}
