import Box from "@mui/material/Box";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";

import useProposal from "../../hooks/useProposal";
import LoadingSpinner from "./LoadingSpinner";

export default function FormikSetup({ children }) {
  const search = useLocation().search;
  const businessId = new URLSearchParams(search).get("id");

  const { loading, loadingError, company: defaultValues } = useProposal(businessId);

  const validate = (values, props) => {
    const errors = {};

    if (!values.name) {
      errors.name = true;
    }
    if (!values.streetAddress) {
      errors.streetAddress = true;
    }
    if (!values.city) {
      errors.city = true;
    }
    if (!values.state) {
      errors.state = true;
    }
    if (!values.zipCode) {
      errors.zipCode = true;
    }

    if (!values.websiteUrl) {
      errors.websiteUrl = true;
    }

    if (!values.primaryContactName) {
      errors.primaryContactName = true;
    }

    const regExpEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      !values.primaryContactEmail ||
      !regExpEmail.test(values.primaryContactEmail)
    ) {
      errors.primaryContactEmail = true;
    }

    /*
    // Note: removed colors and logo fields from sign up form

    var regExpHexColor = /^[0-9A-F]{6}$/i; // matches 6 characters, 0-9 and A-F (case insensitive) only

    var primaryColor;
    if (values.primaryColor) {
      primaryColor = values.primaryColor.replace("#", ""); // remove # if user added it to hex color
    }

    if (!values.primaryColor || !regExpHexColor.test(primaryColor)) {
      errors.primaryColor = true;
    }

    if (values.buttonColor) {
      var buttonColor = values.buttonColor.replace("#", "");
      if (!regExpHexColor.test(buttonColor)) {
        errors.buttonColor = true;
      }
    }
    */

    return errors;
  };

  return (
    <section className="section overflow-visible">
      {loading && <LoadingSpinner displayText="Loading, please wait..." />}
      {!loading && loadingError && (
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { my: 1 },
          }}
        >
          <div className="container">
            <div className="card overflow-visible bg-white">
              <div className="card-body">
               <div style={{display: 'flex', justifyContent:'center'}}>Unable to load<br/><br/>{loading}</div>
               <div style={{display: 'flex', justifyContent:'center'}}>Please contact sales@begrateful.org for assistance</div>
              </div>
            </div>
          </div>
        </Box>
      )}
      {!loading && !loadingError && (
        <Formik
          validate={validate}
          initialValues={defaultValues}
          enableReinitialize={false}
        >
          {() => (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { my: 1 },
              }}
            >
              <div className="container">
                <div className="card overflow-visible bg-white">
                  <div className="card-body">{children}</div>
                </div>
              </div>
            </Box>
          )}
        </Formik>
      )}
    </section>
  );
}
