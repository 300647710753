import { useState } from "react";

import { StripeProvider } from "../../context/stripeContext";
import { PaymentProvider } from "../../context/paymentContext";
import FormikSetup from "./FormikSetup";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";

const Steps = () => {
  const [currentStep, setCurrentStep] = useState(1);

  return (
    <PaymentProvider>
      <FormikSetup>
        <StripeProvider>
          {currentStep === 1 && (
            <>
              <StepOne setCurrentStep={setCurrentStep} />
            </>
          )}

          {currentStep === 2 && (
            <>
              <StepTwo setCurrentStep={setCurrentStep} />
            </>
          )}

          {currentStep === 3 && (
            <>
              <StepThree setCurrentStep={setCurrentStep} />
            </>
          )}
        </StripeProvider>
      </FormikSetup>
    </PaymentProvider>
  );
};

export default Steps;
