import { useState } from "react";
import { useFormikContext } from "formik";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";

import { saveStepOneImage } from "../../helpers/saveImage";
import { usePaymentContext } from "../../context/paymentContext";
import ImageUpload from "./ImageUpload";
import MuiButton from "./MuiButton";
import TextInput from "./TextInput";
import EmailInput from "./EmailInput";
import ColorInput from "./ColorInput";
import LoadingSpinner from "./LoadingSpinner";
import { downloadPdf } from './DownloadPdf';

export default function StepOne({ setCurrentStep }) {
  const { validateForm, values, setValues } = useFormikContext();

  // stepOneSubmitCount is to provide error handling only after clicking go to step 2
  const [stepOneSubmitCount, setStepOneSubmitCount] = useState(0);
  const [emailLogo, setEmailLogo] = useState({ file: null, localUrl: null });
  const [companyLogo, setCompanyLogo] = useState({
    file: null,
    localUrl: null,
  });
  const [emailLogoError, setEmailLogoError] = useState();
  const [companyLogoError, setCompanyLogoError] = useState();
  const [loading, setLoading] = useState(false);
  const [payment] = usePaymentContext();

  const createPdf = async () => {
    downloadPdf(values, payment);
  }


  /*
  Return the Grateful ROI calculator URL
  */
  function calculatorUrl(companyId) {
    if ((companyId != null) && (companyId != '')) {
      return "https://calculator.begrateful.org?id=" + companyId;
    } else {
      return "https://calculator.begrateful.org";
    }
  }


  return (
    <>
      {loading && <LoadingSpinner displayText="Saving your data..." />}
      {!loading && (
        <>
          {values.proposalId != null && (
            <>
              <FormLabel className="legend">Please review and complete your information:</FormLabel>
              <br />
            </>
          )}
          <TextInput
            hideError={stepOneSubmitCount === 0}
            name="name"
            label="Company Name"
            required
          />
          <TextInput
            name="streetAddress"
            label="Street Address"
            hideError={stepOneSubmitCount === 0}
            required
          />
          <TextInput
            name="city"
            label="City"
            hideError={stepOneSubmitCount === 0}
            required
          />
          <TextInput
            name="state"
            label="State"
            hideError={stepOneSubmitCount === 0}
            required
          />
          <TextInput
            name="zipCode"
            label="Zip Code"
            hideError={stepOneSubmitCount === 0}
            required
          />
          <TextInput
            hideError={stepOneSubmitCount === 0}
            name="websiteUrl"
            label="Company Email Domain (for example, company.com)"
            required
          />
          <TextInput
            name="primaryContactName"
            label="Contact Person Name"
            hideError={stepOneSubmitCount === 0}
            required
          />
          <EmailInput
            name="primaryContactEmail"
            label="Contact Person Email"
            hideError={stepOneSubmitCount === 0}
            required
          />
          {/*
          <ImageUpload
            setImage={setEmailLogo}
            image={emailLogo}
            legendText={
              "Company logo for displaying on white background (JPG, PNG or GIF) *"
            }
          />
          {emailLogoError && <Alert severity="error">{emailLogoError}</Alert>}
          <ImageUpload
            imageBackgroundColor={
              values.primaryColor ? values.primaryColor : "#808080"
            }
            setImage={setCompanyLogo}
            image={companyLogo}
            legendText={
              "Optional: White or knockout logo for displaying on a color background (JPG, PNG or GIF)"
            }
          />
          {companyLogoError && (
            <Alert severity="error">{companyLogoError}</Alert>
          )}
          <ColorInput
            name="primaryColor"
            label="Primary company color (hex code) (use 000000 for black)"
            hideError={stepOneSubmitCount === 0}
            required
          />
          <ColorInput
            name="buttonColor"
            label="Optional: Secondary company color (hex code)"
            hideError={stepOneSubmitCount === 0}
          />
          */}

          <MuiButton
            onClick={() => {
              setStepOneSubmitCount((count) => count + 1);

              /*
              const isNotValidPhotoType = (photoFile) =>
                !photoFile.file.name.toLowerCase().includes(".jpg") &&
                !photoFile.file.name.toLowerCase().includes(".jpeg") &&
                !photoFile.file.name.toLowerCase().includes(".png") &&
                !photoFile.file.name.toLowerCase().includes(".gif");
              */
              validateForm().then(async (errors) => {
                if (Object.keys(errors).length === 0) {
                  let error = false;

                  /*
                  if (!emailLogo.file) {
                    setEmailLogoError("Please add a logo file");
                    error = true;
                  } else if (isNotValidPhotoType(emailLogo)) {
                    setEmailLogoError("Please use a JPG, PNG or GIF logo file");
                    error = true;
                  } else {
                    setEmailLogoError();
                  }

                  if (companyLogo.file && isNotValidPhotoType(companyLogo)) {
                    setCompanyLogoError(
                      "Please use a JPG, PNG or GIF logo file"
                    );
                    error = true;
                  } else {
                    setCompanyLogoError();
                  }
                  */

                  if (!error) {
                    setLoading(true);

                    /*
                    const images = await saveStepOneImage(
                      emailLogo,
                      companyLogo,
                      values
                    );
                    */

                    // setValues({ ...values, ...images });
                    setValues({ ...values });

                    setLoading(false);
                    setCurrentStep(2);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }
                } else {
                  // scroll to top if errors
                  window.scrollTo({ top: 200, behavior: "smooth" });
                }
              });
            }} 
          >
            Go on to step 2
          </MuiButton>
          <br/><br/><br/>
          Or:
          <br/>
          <Button
            style={{ marginTop: 15 }}
            variant="contained"
            color="info"
            href={calculatorUrl(values.companyId)}
          >Return on Investment Calculator
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            style={{ marginTop: 15 }}
            variant="contained"
            color="info"
            onClick={createPdf}
          >Download PDF Proposal
          </Button>
        </>
      )}
    </>
  );
}
