import { createContext, useContext, useState } from "react";

const PaymentContext = createContext();

function PaymentProvider(props) {
  const value = useState();
  return <PaymentContext.Provider value={value} {...props} />;
}

function usePaymentContext() {
  const context = useContext(PaymentContext);
  if (context === undefined) {
    throw new Error("usePaymentContext must be used within a PaymentProvider");
  }
  return context;
}

export { PaymentProvider, usePaymentContext };
