import Button from "@mui/material/Button";

const MuiButton = (props) => (
  <Button
    style={{ marginTop: 15 }}
    variant="contained"
    color="success"
    {...props}
  />
);

export default MuiButton;