import FormLabel from "@mui/material/FormLabel";

import styled from "@emotion/styled";

const ImageUploadContainer = styled.div`
  grid-template-columns: 1fr;
  display: grid;
  grid-gap: 0.5em;
  align-items: center;
  padding: 14px;
  margin: 14px 0;
  border-radius: 4px;
  border: 0.5px solid rgba(0, 0, 0, 0.25);

  label[role="button"] {
    width: 200px;
  }
`;

export default function ImageUpload({ legendText, image, setImage, imageBackgroundColor }) {

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setImage({ file: file, localUrl: fileUrl });
    }
  };

  return (
    <ImageUploadContainer>
      <FormLabel className="legend">{legendText}</FormLabel>
      <input type="file" onChange={handleChange} />
      {image.localUrl && (
        <img
          src={image.localUrl}
          alt="Logo preview"
          style={{ maxWidth: 300, maxHeight: 300, padding: 10, ...(imageBackgroundColor && {backgroundColor: imageBackgroundColor})}}
        />
      )}
    </ImageUploadContainer>
  );
}
